.topbar--bgovz {
  --svg-primary-color: hsla(0, 0%, 15%, 1);
  position: relative;
  z-index: 20;
  background-color: hsla(0, 0%, 100%, 1); }
  .topbar--without-navbar--9YZ1V {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 10%); }
  .topbar__top-menu--3xw0v {
    display: flex;
    align-items: center; }
  .topbar__logo-wrap--1pWnv {
    padding-right: 10px; }
  .topbar__top-menu-slot--1X2ap {
    display: flex;
    align-items: center;
    margin-left: auto; }
  .topbar__top-menu-inner-slot--3otoT {
    margin-left: 10px; }
    @media (min-width: 990px) {
      .topbar__top-menu-inner-slot--3otoT {
        margin-left: 20px; } }
  .topbar__header-phone-link--2wKUw {
    display: flex;
    flex: 0 0 auto; }
  @media (min-width: 990px) {
    .topbar__wrapper-phone-icon--2TWeG {
      display: none; } }
  @media (max-width: 989px) {
    .topbar__phone-text--pJZuT {
      display: none; } }
  @media (max-width: 989px) {
    .topbar__grid-container--3Rsy8 {
      max-width: none; } }
  @media (max-width: 989px) {
    .topbar__grid-column--2jKP3 {
      width: 100%; } }
