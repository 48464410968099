.topbar-profile--3plBw {
  display: flex;
  align-items: center; }
  .topbar-profile__text--2ZFcL {
    padding-left: 5px; }
    .topbar-profile__text--profile--nHkM5 {
      cursor: default; }
    @media (max-width: 989px) {
      .topbar-profile__text--2ZFcL {
        display: none; } }
  .topbar-profile__icon--2tjbX {
    position: relative;
    top: -1px; }
