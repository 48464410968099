.region--XwUr- {
  --svg-primary-color: hsla(0, 0%, 65%, 1);
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 5px;
  overflow: hidden; }
  .region__icon--1UBqB {
    position: relative;
    top: -1px; }
  .region__text--3zPSM {
    overflow: hidden;
    color: hsla(0, 0%, 65%, 1);
    white-space: nowrap;
    text-overflow: ellipsis; }
  .region__select--3aB1c {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0; }
    .region__select--3aB1c:disabled {
      cursor: default; }
