.header-dropdown--lUGGa {
  position: relative; }
  .header-dropdown__content-container--GrXzK {
    position: absolute;
    top: 100%;
    right: 0;
    transform: translateY(-10px);
    opacity: 0;
    transition: all 0.25s ease-in-out;
    pointer-events: none; }
  .header-dropdown--lUGGa:hover > .header-dropdown__content-container--GrXzK {
    transform: translateY(0);
    opacity: 1;
    pointer-events: auto; }
  .header-dropdown__content--shYvn {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-top: 15px;
    padding: 20px 10px;
    background-color: hsla(0, 0%, 100%, 1);
    border-radius: 4px;
    box-shadow: 0 6px 14px rgba(0, 0, 0, 16%); }
  .header-dropdown__content-menu-item--2cbU9 {
    display: flex;
    white-space: nowrap; }
